import { LazyObserver } from "cadfem-modules/src/classes";
import { log } from "cadfem-modules/utility";


[
  { name:'HeaderSticky', v:2, _:()=>import('cadfem-modules/src/sync/HeaderSticky_v2'),},
  { name:'DropDown', v:2, _:()=>import('cadfem-modules/src/sync/DropDown_v2'),},
  // { name:'Form', v:2, _:()=>import('cadfem-modules/src/sync/Form_v2'), },
  // { name:'FormCaptcha', _:()=>import('cadfem-modules/src/sync/FormCaptcha'), },
  // { name:'SmoothScroll', v:2, _:()=>import('cadfem-modules/src/sync/SmoothScroll_v2'),},
  // { name:'Select2', v:3, _:()=>import('cadfem-modules/src/sync/Select2_v3'), selector:'.select-2',},
  // { name:'InputMask', v:2, _:()=>import('cadfem-modules/src/sync/InputMaskPhone_v2'), selector:'.inputmask-phone', },
  { name:'scheduleFilter', _:()=>import(/* webpackChunkName: "scheduleFilter" */ './../modules/local/scheduleFilter'), },
  { name:'FormCustom', _:()=>import(/* webpackChunkName: "scheduleFilter" */ './../modules/local/FormCustom'), },
].forEach( block => {
  (new LazyObserver({
    selector: block.selector || `[data-component="${block.name}"]`,
    activeClass: 'lazy',
    observe: ({target, IO, activeClass}) => {
      const version = block.v ? `_v${block.v}` : '';
      block._()
        .then( module => (new module.default(block.name, target, block.params)).start() )
        .then( () => log(`${block.name}${version}`) )
        .then( () => target.removeAttribute(activeClass) )
        .catch( error => console.error(`An error occurred while loading the component ${block.name}`, error) );
      IO.unobserve(target);
    }
  })).init();
});
